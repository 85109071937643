import React from 'react';
import logo from './logo.png';
import twitterLogo from './twitter.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1>Wolf Game App</h1>
        <p>Coming soon to iOS and Android.</p>
        <a
          href='https://twitter.com/WolfGameApp'
          title='Link to Wolf Game App Twitter profile.'
        >
          <img
            src={twitterLogo}
            className='App-twitter-logo'
            alt='Twitter Logo'
          />{' '}
        </a>
      </header>
    </div>
  );
}

export default App;
